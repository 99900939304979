<template>
  <el-row class="table">
    <span class="title">试听管理</span>
    <el-row class="row-bg">
      <el-input
        placeholder="输入学员姓名"
        v-model.trim="name"
        class="tb-sm-input"
        @keyup.enter.native="search"
      >
      </el-input>
      <el-input
        placeholder="输入学员用户名"
        v-model.trim="loginName"
        class="tb-sm-input"
        @keyup.enter.native="search"
      >
      </el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
      <el-button type="info" size="medium" @click="add" class="tb-button">新增试听</el-button>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="校区" align="center" prop="campusName"></el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>
      <el-table-column label="是否过期" align="center">
        <template slot-scope="scope">
          <span v-text="PastTime(scope.row.expires_time)"></span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span v-text="getStateNum(scope.row.state)" :style="'color'+':'+getStateNumColor(scope.row.state)"></span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- addListen -->
<!--    <add-listen :show="listenDialog" @close="listenDialog=false;editListen=null" :editListen="editListen" @updateView="getListListenStu"></add-listen>-->
  </el-row>
</template>

<script>


import { getListListenStu } from '@/api/senate/listen'
import { formatTate } from '@/utils/formatDate'
import getStateNum from '@/mixins/getStateText'
import getStateNumColor from '@/mixins/getStateColor'

export default {
  data () {
    return {
      name: '',
      loginName: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      listenDialog: false,
      editListen: null
    }
  },
  mixins: [getStateNum, getStateNumColor],
  created () {
    this.getListListenStu()
  },
  methods: {
    async getListListenStu () {
      const res = await getListListenStu({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        isTry: true,
        loginName: this.loginName ? this.loginName : '',
        name: this.name ? this.name : ''
      })
      console.log('res', res)
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    search () {
      this.getListListenStu()
    },

    reset () {
      this.name = ''
      this.loginName = ''
    },

    add () {
      this.listenDialog = true
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListListenStu()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListListenStu()
    },

    PastTime (time) {
      let expiresTime = formatTate(time)
      expiresTime = Date.parse(new Date(expiresTime))
      let presentTime = formatTate(new Date())
      presentTime = Date.parse(presentTime)
      if (Number(expiresTime) > Number(presentTime)) {
        return '否'
      } else {
        return '是'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
